import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 4
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"TWO"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={"Which of the following are required elements of team time?"}
        answers={[
          "Consistency",
          "Players-first mentality",
          "Round structure",
          "All of the above",
        ]}
        correctAnswerNumber={3}
        answerDetail={
          "Consistent, Players First, and Round are the 3 elements to a successful Team Time."
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 2, 6)
